<template>
  <div id="app">
    <b-container class="mt-5">
      <component :is="compiledTemplate"></component>

    </b-container>
  </div>
</template>
<script>
import config from "./config/config.js";
import axios from "axios";
import moment from "moment";
const compiler = require("vue-template-compiler");
import Vue from "vue";
export default {
  props: ["type", "community", "form"],
  data() {
    return {
      currentForm: {},
      communityData: {},
      draftSubmission: {},
      _sessionId: null,
      compiledTemplate: null,
      submissionData: {}
    };
  },
  mounted() {
    let self = this;
    this.$session.start();

    let loginData = {
      userName: this.$session.id(),
      password: "",
      ApplicationKey: config.APP_KEY,
    };


    self.draftSubmission = window.draftSubmission;
    self.submissionData = (self.draftSubmission.submission) ? self.draftSubmission.submission.data : self.draftSubmission.data
    self.communityData = window.communityData

    axios
      .get(config.API_URL + "/api/templateForForm/" + self.form)
      .then((response) => {
        let definition = JSON.parse(response.data.definition).html;
       

        let compSpec = {
          ...compiler.compileToFunctions(definition),
          data: () => ({
            templateData: (self.submissionData) ? self.submissionData : {},
            communityData: (self.communityData) ? self.communityData : {},
            CurrentDate: moment().format("DD.MM.YYYY"),
            orgData: {},
            viewListComponentKeyDynamic: self.$root.$children[0].viewListComponentKey,

          }),
          components: {
          },
          mounted() {
            let self = this;
            this.orgData = self.draftSubmission
          },
          methods: {
            formatDate(date, format = "DD.MM.YYYY") {
              return moment(date).format(format);
            },

            formatTime(date, format = "HH:mm") {
              return moment(date, "YYYY-MM-DD HH:mm:ss").format(format);
            },
          },
        };
        this.compiledTemplate = Vue.extend(compSpec);
      });
  },

  methods: {
    resolve(path, obj) {
      return path.split("_").reduce(function (prev, curr) {
        return prev ? prev[curr] : null;
      }, obj || self);
    },

    replaceKeysInObj(obj, orgKey, value) {
      if (obj != null) {
        Object.keys(obj).map((key) => {
          if (typeof obj[key] === "object") {
            this.replaceKeysInObj(obj[key], orgKey, value);
          }
          if (obj[key] != null) {
            if (obj[key]["key"] === orgKey) {
              obj[key].html = value;
            }
          }
        });
      }
      return obj;
    },

    formatDate(date, format = "DD.MM.YYYY") {
      return moment(date).format(format);
    },

    formatTime(date, format = "HH:mm") {
      return moment(date, "YYYY-MM-DD HH:mm:ss").format(format);
    },
  },
};
</script>

<style>
@import "https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css";
</style>

