<template>
  <span v-if="compiledTemplate != null" :key="$root.$children[0].viewListComponentKey">
    <component :is="compiledTemplate"></component>

  </span>
</template>

<script>
import config from "../config/config.js";
import axios from "axios";
import moment from "moment";
const compiler = require("vue-template-compiler");
import Vue from "vue";


export default {
  props: {

    templateId: { type: Number }
  },

  data: function () {
    return {

      compiledTemplate: null,

      viewListComponentKey: moment().format("x"),
      communityData: {},
      draftSubmission: {},
      submissionData: {}



    };
  },

  created() {
    let self = this;
    self.draftSubmission = window.draftSubmission;
    self.submissionData = (self.draftSubmission.submission) ? self.draftSubmission.submission.data : self.draftSubmission.data
    self.communityData = window.communityData
    this.renderComponent(this.templateId)


  },

  methods: {


    renderComponent(templateId) {
      let self = this;
      let token = self.$session.get("token",);

      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;
      if (templateId > 0) {
        axios
          .get(config.API_URL + "/api/template/" + templateId)
          .then((response) => {
            let definition = JSON.parse(response.data.definition).html;

            let compSpec = {
              ...compiler.compileToFunctions(definition),
              data: () => ({
                componentData: self.list,
                orgData: {},
                templateData: (self.submissionData) ? self.submissionData : {},
                communityData: (self.communityData) ? self.communityData : {},

                viewListComponentKeyDynamic: self.$root.$children[0].viewListComponentKey,

                testProp: "TEST PROP",
              }),

              mounted() {
                let self = this;
                this.orgData = self.componentData
              },
              methods: {

              },
            };
            self.compiledTemplate = Vue.extend(compSpec);

            self.$root.$children[0].viewListComponentKey = moment().format("X")
          });
      } else {
        self.renderedHTML = "Kein Template gewählt";
      }

    },


  },

};
</script>
