export default {

    /*    API_URL: process.env.NODE_ENV === 'production' ?
        'https://localhost:44355/' : 'https://localhost:44355/',*/

    API_URL: process.env.NODE_ENV === 'production' ?
        'https://ozg-digital.de/api/' : 'https://localhost:44355/',

    /*    API_URL: process.env.NODE_ENV === 'production' ?
          'https://localhost:44355/' : 'https://localhost:44355/',*/
    /* API_URL: process.env.NODE_ENV === 'production' ?
           'https://ozgqs.digitalformular.org/api/' : 'https://localhost:44355/', */


    VERSION: "1.0.1",
    APP_KEY: "1f9adc51b156d959688e0d86e6dc5c39"
}